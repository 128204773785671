import { ChartTypeRegistry } from "chart.js"

//TRANSACTIONS
export interface TollFile {
    fileName: string,
    type: 'csv' | 'xlsx',
    data: any
}
export interface TollGetImportedFilesRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {}
}
export interface TollGetImportedFilesResponse {
    pagination: {
        totalResults: number
    },
    result: {
        id: number,
        nomeFile: string,
        idEnte: number,
        descrizioneEnte: string,
        dataCaricamento: string,
        totaleRighe: number,
        totaleDuplicate: number,
        totaleScartate: number,
        totalePreseInCarico: number,
        isLogScartiAvaliable: boolean,
        logScartiFullPath: string
    }[]
    code: number
}
export interface TollLoadTransactionFileRequest {
    nomeFile: string,
    fileTransazioni: any,
    idEnte: number
}
export interface TollTransactionsSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        idCliente: number,
        idEnte: string,
        idVeicolo: number,
        idDispositivo: number,
        dataTransazioneDa: string,
        dataTransazioneA: string,
        dataCompetenzaDa: string,
        dataCompetenzaA: string,
        idDipartimento: string,
        importoDa: number,
        importoA: number,
        idTipoStatusTransazioneList: Array<number>,
        idTipoTransazione: number,
        idLocatario: number,
        esportata: boolean,
        codiceDispositivoTransazioni: string
    }
}
export interface TollTransactionsSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollTransaction[]
    code: number
}
export interface TollTransaction {
    cliente: string
    ente: string,
    riferimentoEnte: string,
    dataTransazione: string,
    dispositivoRilevato: string
    importo: string,
    targa: string,
    dipartimento: string,
    dataCompetenza: string,
    id: number
    dataFatturazione: string,
    fatturaNumero: string,
    idDipartimento: number,
    idVeicolo: number,
    idDispositivo: number,
    casello: string,
    locatario: string,
    idTipoTransazione: number,
    idTipoStatusTransazione: number,
    dataPubblicazione: string
}
export interface TollTransactionItem {
    title: string,
    transactionsIds: Array<number>,
    filters: object,
    id: number
}
export interface TollLotsSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        idCliente: number,
        idDipartimento: number,
        idLocatario: number,
        fatturaNumero: string,
        codiceLotto: string,
        dataCreazioneLottoDa: string,
        dataCreazioneLottoA: string,
        dataEmissioneFatturaDa: string,
        dataEmissioneFatturaA: string,
        dataScadenzaFatturaDa: string,
        dataScadenzaFatturaA: string
    }
}
export interface TollLotsSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollLot[]
    code: number
}
export interface TollLot {
    codiceLotto: string,
    dataCreazioneLotto: string,
    cliente: string,
    dipartimento: string,
    locatario: string,
    fatturaNumero: string,
    dataEmissioneFattura: string,
    dataScadenzaFattura: string,
    netto: string,
    tasse: string,
    totale: string,
    idTipoStatusFatturazioneIntestazioni: number,
    idGestioneDocumenti: number,
    idFatturazioneIntestazioni: number
}
export interface TollInvoiceEditpayload {
    idFatturazioneIntestazioni: number,
    numero: string,
    dataEmissione: string,
    dataScadenza: string
}

export class TollLotSearchConfiguration {
    public abilitaAvviaFatturazione: boolean
    constructor() {
        this.abilitaAvviaFatturazione = false;
    }
}

//SUBSCRIPTIONS
export interface TollActivation {
    idTipoAttivazione: number,
    targa: string,
    idTarga: number,
    tipoDispositivo?: any,
    tipoDispositivoFittizio: number,
    attivareDispositivoFisico?: boolean,
    FEPlateError?: string
    FETipoDispositivoError?: string,
    FEIdTipoAttivazioneError?: string,
    FETipoDispositivoFittizioError?: string,
    FEFromFile?: boolean,
}
export interface TollDevicesSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        id: number,
        codiceDispositivo: string,
        idEnte: number,
        idCliente: number,
        idTipoDispositivo: number,
        idDipartimento: number,
        idTipoStatusDispositivo: number,
        idVeicolo: number,
        idLocatario: number,
        numeroSeriale: string,
    }
}
export interface TollDevicesSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollDevice[]
    code: number
}
export interface TollDevice {
    clienteDenominazione: string,
    idCliente: number,
    codiceDispositivo: string,
    enteDenominazione: string,
    locatarioDenominazione: string,
    idEnte: number,
    tipoDispositivo: any,
    tipologia: string,
    dataInserimento: string,
    idTipoDispositivo: number,
    idTipoStatusDispositivo: number,
    idDispositivo: number,
    numeroSeriale: string,
    FEFromFile?: boolean,
    FETipoDispositivoError?: string,
    FECodiceError?: string,
}
export interface TollVehiclesSubbedSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        idCliente: number,
        idVeicolo: number,
        idEnte: number,
        isAttiva: boolean,
        idTipoDispositivoFittizio: number
    }
}
export interface TollVehiclesSubbedSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollVehicleRow[],
    code: number
}
export interface TollPlatesSubsSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        idCliente: number,
        idVeicolo: number,
        idEnte: number,
        idTipoStatus: boolean
    }
}
export interface TollPlatesSubsSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollVehicleRow[]
    code: number
}
export interface TollDeviceAssociations {
    associazioni:
    {
        codiceDispositivo: string,
        targa: string,
        dataInizio: string,
        dataFine: string
    }[],

    azioniDisponibili: {
        isDisattivaEnabled: boolean,
        messaggioDisattiva: string,
        isCambioVeicoloEnabled: boolean,
        messaggioCambioVeicolo: string,
        isSostituzioneEnabled: boolean,
        messaggioSostituzioneVeicolo: string
    }
}
export interface TollDeviceLoadItem {
    codiceDispositivo: string,
    numeroSeriale: string,
    tipoDispositivo: { id: number, descrizione: string },
    FETipoDispositivoError?: string,
    FECodiceError?: string,
    FENumeroSerialeError?: string,
    FEFromFile?: boolean
}
export interface TollVehiclesUtilizersSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        idCliente: number,
        idVeicolo: number,
    }
}
export interface TollVehiclesUtilizersSearchResponse {
    pagination: {
        totalResults: number
    },
    result: Array<{
        id: number,
        codiceDispositivo: string,
        tipologiaDispositivo: string,
        targa: string,
        idVeicolo: number,
        dataInizio: string,
        dataFine: string
    }>,
    code: number,
}
//CUSTOMERS
export interface TollCustomersSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        id: number,
        denominazione: string,
        indirizzo: string,
        telefono: string,
        email: string,
        referente: string,
        cap: string,
        localita: string,
        codiceUnivoco: string,
        idStato: number,
        idTipoStatus: number
    }
}
export interface TollCustomersSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollCustomerRow[],
    code: number
}
export interface TollCustomerCreate {
    denominazione: string,
    indirizzo: string,
    telefono: string,
    email: string,
    referente: string,
    cap: string,
    localita: string,
    codiceUnivoco: string,
    idStato: number
}
export interface TollCustomerEdit {
    id: number,
    denominazione: string,
    indirizzo: string,
    telefono: string,
    email: string,
    referente: string,
    cap: string,
    localita: string,
    codiceUnivoco: string,
    idStato: number
}
export interface TollCustomerRow {
    id: number,
    denominazione: string,
    indirizzo: string,
    telefono: string,
    email: string,
    referente: string,
    cap: string,
    localita: string,
    codiceUnivoco: string,
    statoDenominazione: string,
    idTipoStatus: number
}
export interface TollCustomerDetail {
    id: null,
    denominazione: string,
    indirizzo: string,
    telefono: string,
    email: string,
    referente: string,
    cap: string,
    localita: string,
    codiceUnivoco: string,
    stato: string,
    tipoStatusCliente: number,
    dipartimenti: any[],
    locatari: any[],
    statoDenominazione?: string
}

//ORDERS
export interface TollOrdersSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        dataInserimentoDa: string,
        dataInserimentoA: string,
        dataPresaInCaricoDa: string,
        dataPresaInCaricoA: string,
        idTipoOrdine: number,
        idTipoStatusOrdine: number,
        idEnte: number,
        idLotto: number,
        idCliente: number,
        idDipartimento: number,
        idTargaOrigine: number,
        idDispositivoOriginale: number,
        idUtentePresaInCarico: number,
        isPresaInCarico: boolean,
        idLocatario: number
    }
}
export interface TollOrdersSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollOrderRow[]
    code: number
}
export interface TollOrderRow {
    clienteDenominazione: string,
    codiceLotto: string,
    dataInserimento: string,
    dipartimentoCodiceDenominazione: string,
    codiceDispositivoOrigine: string,
    id: number,
    idTipoMotivazioneOrdine: number,
    idTipoOrdine: number,
    lavorabile: boolean,
    nominativoUtenteOrigine: string,
    noteAnomalia: string,
    targaOrigine: string,
    targaDestinazione: string,
    idTipoDispositivo: number,
    idTipoStatusOrdine: number,
    enteDenominazione: string,
}
export interface TollOrderDetail {
    id: number,
    codiceLotto: string,
    idTipoMotivazioneOrdine: number,
    noteMotivazioneOrdine: string,
    dipartimentoCodiceDenominazione: string,
    idTipoDispositivo: number,
    targaVeicoloOrigine: string,
    targaVeicoloDestinazione: string,
    codiceDispositivoOrigine: string,
    codiceDispositivoDestinazione: string,
    idTipoStatusOrdine: number,
    dataLavorazione: string,
    noteAnomalia: string,
    dispositivoAssociato: string,
    dataPresaInCarico: string,
    idUtenteOrigine: number,
    nominativoUtenteOrigine: string,
    idUtentePresaInCarico: number,
    nominativoUtentePresaInCarico: number,
    clienteDenominazione: string,
    dataInserimento: string,
    idTipoOrdine: number,
    enteDenominazione: string,
    idOrdinePadre: number,
    idCliente: number,
    idEnte: number,
    idDipartimento: number,
    idVeicoloOriginale: number,
    idVeicoloDestinazione: number,
    idDispositivoCambioTarga: number,
    codiceDispositivoCambioTarga: string,
    FEIsSottoscrizione: boolean
}
export interface SearchPageConfig {
    descrizione: string,
    totali: number,
    filtri: any,
    id: number,
    maxOrdiniEsportabili: number,
    messaggio: string,
    FECustomLists?: any
}

//USERS
export class TollUserConfiguration {
    enti: { id: number, descrizione: string }[];
    stati: { id: number, descrizione: string }[];
    tipoDispositivo: { id: number, descrizione: string, isFittizio: boolean }[];
    tipoDispositivoFittizio: { id: number, descrizione: string, isFittizio: boolean }[];
    tipoEnte: { id: number, descrizione: string }[];
    tipoMotivazioneOrdine: { id: number, descrizione: string }[];
    tipoOrdine: { id: number, descrizione: string }[];
    tipoRuolo: { id, descrizione, isBackOffice }[];
    tipoStatusClienti: { id: number, descrizione: string }[];
    tipoStatusDipartimenti: { id: number, descrizione: string }[];
    tipoStatusDipositivo: { id: number, descrizione: string }[];
    tipoStatusOrdine: { id: number, descrizione: string }[];
    tipoStatusTransazioni: { id: number, descrizione: string }[];
    tipoStatusUtilizzatori: { id: number, descrizione: string }[];
    tipoStatusVeicoli: { id: number, descrizione: string }[];
    tipoTransazione: { id: number, descrizione: string }[];
    tipoVeicolo: { id: number, descrizione: string }[];
    tipoStatusUtenti: { id: number, descrizione: string }[];
    tipoStatusLocatari: { id: number, descrizione: string }[];
    tipoStatusContratti: { id: number, descrizione: string }[];
    tipoAlimentazioneVeicolo: { id: number, descrizione: string }[];
    nullYesNoFE: { id: boolean, descrizione: string }[];
    utenteToll: {
        userId: number,
        ruoliToll: { idCliente: number, idDipartimento: number, idTipoRuolo: number, isBackOffice: boolean, isReadOnly: boolean }[],
        clienti: { id: number, descrizione: string }[],
        isBackOffice: boolean,
        isReadOnly: boolean,
        idRuoloSafo: number
    }
    constructor() {
        this.enti = [];
        this.stati = [];
        this.tipoDispositivo = [];
        this.tipoEnte = [];
        this.tipoMotivazioneOrdine = [];
        this.tipoOrdine = [];
        this.tipoRuolo = [];
        this.tipoStatusClienti = [];
        this.tipoStatusDipartimenti = [];
        this.tipoStatusDipositivo = [];
        this.tipoStatusOrdine = [];
        this.tipoStatusTransazioni = [];
        this.tipoStatusUtilizzatori = [];
        this.tipoStatusVeicoli = [];
        this.tipoTransazione = [];
        this.tipoVeicolo = [];
        this.utenteToll = {
            userId: null,
            ruoliToll: [],
            clienti: [],
            isBackOffice: null,
            idRuoloSafo: null,
            isReadOnly: null
        }
        this.nullYesNoFE = [];
    }
}
export interface TollUsersSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        nome: string,
        cognome: string,
        idTipoRuolo: number,
        idCliente: number,
        idDipartimento: number,
        idTipoStatusUtente: boolean,
    }
}
export interface TollUsersSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollUser[]
    code: number
}
export interface TollUser {
    email: string,
    nome: string,
    cognome: string,
    telefono: string,
    link: string,
    id: number,
    idUtentePortaleSafo: number,
    idTipoStatusUtente: number,
    dataInserimento: string,
    nominativo: string,
    ruoliToll: Array<TollRole>,
}
export interface TollRole {
    idRuolo: number,
    idTipoRuolo: number,
    tipoRuoloDescrizione: string,
    idCliente: number,
    clienteDescrizione: string,
    idDipartimento: number,
    dipartimentoDescrizione: string,
    isBackOffice: string,
    listaLocatari: Array<{ id: number, descrizione: string }>,
    listIdLocatari: Array<number>
}

//VEHICLES
export interface TollVehiclesSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        id: number,
        idDipartimento: number,
        idCliente: number,
        targaAttiva: string,
        telaio: string,
        assi: number,
        codiceEsterno: string,
        idTipoVeicolo: number,
        idTipoStatus: boolean,
    }
}
export interface TollVehiclesSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollVehicleRow[],
    code: number
}
export interface TollVehicleRow {
    targa: string
    tipoVeicoloDescrizione: string,
    codiceDipartimento: string,
    codiceEsterno: string,
    marca: string,
    modello: string,
    colore: string,
    assi: string,
    entiDaAttivare: string,
    clienteDescrizione: string,
    enteDescrizione: string,
    dataInizio: string,
    dataFine: string,
    isSottoscrizioneDisattivabile?: boolean,
    telaio?: string,
    id: number
}
export interface TollVehicleDetail {
    id: number,
    targa: string,
    idTipoVeicolo: number,
    cliente: object,
    dipartimento: object,
    marca: string,
    modello: string,
    colore: string,
    assi: string,
    idTipoStatusVeicolo: number,
    dataInserimento: string,
    telaio: string,
    stato: any,
    codiceEsterno: string,
    isAssociazioneVeicoloDispositivoAttiva: boolean,
    isSottoscrizioneAttiva: boolean,
    peso: number,
    anno: number,
    idTipoAlimentazioneVeicolo: number
}
export interface TollVehicleCreate {
    idCliente: number,
    targaAttiva: string,
    telaio: string,
    idDipartimento: number,
    marca: string,
    modello: string,
    colore: string,
    assi: number,
    idStato: number,
    idTipoVeicolo: number,
    codiceEsterno: string,
    peso: number,
    anno: number,
    idTipoAlimentazioneVeicolo: number
}
export interface TollVehicleEdit {
    id: number,
    marca: string,
    modello: string,
    colore: string,
    assi: number,
    idDipartimento: number,
    idTipoVeicolo: number,
    codiceEsterno: string,
    peso: number,
    anno: number,
    idTipoAlimentazioneVeicolo: number,
    targaAttiva: string
}
export interface TollLoadVehiclesFileRequest {
    nomeFile: string,
    fileVeicoli: any,
    idCliente: number
}
export interface TollVheicleViolationsSearch {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        idCliente: number,
        idVeicolo: number
    }
}
export interface TollVheicleViolationsSearchResponse {
    result:
    {
        targa: string,
        stato: string,
        numeroVerbale: string,
        dataOraInfrazione: string,
        dataNotifica: string,
        ente: string,
        infrazione: string,
        tipoDocumento: string,
        importo: number,
        gestita: boolean,
        tipoGestione: string,
    }[],
    pagination:
    {
        totalResults: number
    }
}
export interface TollVheicleTollsSearch {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        idCliente: number,
        idVeicolo: number
    }
}
export interface TollVheicleTollsSearchResponse {
    result:
    {
        enteDenominazione: string,
        anno: number,
        numeroTransazioni: number,
        importoTotale: number
    }[],
    pagination:
    {
        totalResults: number
    }
}

//DEPARTMENTS
export interface TollDepartmentsSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        id: number,
        idCliente: number,
        idTipoStatusDipartimento: number,
        codice: string,
        denominazioneCliente: string,
        denominazione: string,
        email: string,
        telefono: string
    }
}
export interface TollDepartmentsSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollDepartmentRow[],
    code: number
}
export interface TollDepartmentRow {
    id: number,
    codice: string,
    denominazione: string,
    email: string,
    telefono: string,
    clienteDenominazione: string,
    idTipoStatusDipartimento: number,
    denominazioneDipartimentoPadre: string
}
export interface TollDepartmentDetail {
    id: number,
    codice: string,
    denominazione: string,
    email: string,
    telefono: string,
    cliente: {
        id: number,
        denominazione: string,
        indirizzo: string,
        telefono: string,
        email: string,
        referente: string,
        cap: string,
        localita: string,
        codiceUnivoco: string,
        stato: string,
        status: string,
        idTipoStatus: number
    },
    tipoStatusDipartimento: {
        id: number,
        descrizione: string
    },
    dipartimentoPadre: {
        id: number,
        codice: string,
        denominazione: string,
        email: string,
        telefono: string,
        cliente: {
            id: number,
            denominazione: string,
            indirizzo: string,
            telefono: string,
            email: string,
            referente: string,
            cap: string,
            localita: string,
            codiceUnivoco: string,
            stato: string,
            status: string,
            idTipoStatus: number
        },
        tipoStatusDipartimento: {
            id: number,
            descrizione: string
        }
    },
    dataInserimento: string,
    referente: string
}
export interface TollDepartmentCreate {
    idCliente: number,
    codice: string,
    denominazione: string,
    email: string,
    telefono: string,
    idDipartimentoPadre: number,
    referente: string
}
export interface TollDepartmentEdit {
    id: number,
    codice: string,
    denominazione: string,
    email: string,
    telefono: string,
    referente: string
}

//UTILIZERS
export interface TollUtilizersSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        id: number,
        idCliente: number,
        denominazioneCliente: string,
        idDipartimento: number,
        idTipoStatus: number,
        nome: string,
        cognome: string,
        email: string,
        telefono: string
    }
}
export interface TollUtilizersSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollVehicleRow[],
    code: number
}
export interface TollUtilizerRow {
    id: number,
    clienteDenominazione: string,
    codiceEsterno: string,
    dipartimentoCodiceDenominazione: string,
    idTipoStatusUtilizzatore: number,
    nome: string,
    cognome: string,
    socialSecurityNumber: string,
    email: string,
    telefono: string
}
export interface TollUtilizerDetail {
    id: number,
    codiceEsterno: string,
    idTipoStatusUtilizzatore: number,
    nome: string,
    cognome: string,
    email: string,
    telefono: string,
    cliente: object,
    dipartimento: object
}
export interface TollUtilizerCreate {
    idCliente: number,
    idDipartimento: number,
    nome: string,
    cognome: string,
    email: string,
    telefono: string,
    codiceEsterno: string
}
export interface TollUtilizerEdit {
    id: number,
    idDipartimento: number,
    nome: string,
    cognome: string,
    email: string,
    telefono: string,
    codiceEsterno: string
}

//DASHBOARD
export interface TollDashboardConfig {
    customers: Array<{ idCliente: number, denominazione: string, groups: Array<TollDashboardGroup> }>,
    layout: 'rows' | 'columns'
}
export interface TollDashboardConfigCache {
    groups: Array<TollDashboardGroup>,
    layout: 'rows' | 'columns'
}
export interface TollDashboardGroup {
    controls: Array<TollDashboardControl>,
    id: number,
    customerId: number,
    endpoint: string,
    lastUpdateDate: string,
    type: 'badge' | 'doubleBadge' | 'table' | 'chart',
    FEHidden: boolean,
    FELoading: boolean,
    FEWidth: number,
    FEHeight: number,
    FEIndex: number,
    FEChartType: keyof ChartTypeRegistry
}
export interface TollDashboardControl {
    label: string,
    rgbColor: string,
    icon: string,
    type: 'badge' | 'doubleBadge' | 'table' | 'chart',
    value: any,
    FEAction: TollSearchObject,
    FEHidden: boolean,
    FEChartData: {
        type: keyof ChartTypeRegistry,
        labels: Array<string>,
        datasets: Array<{ label: string, data: Array<number>, backgroundColor: string }>
    },
    FEChartOptions: any,
    FETableOptions: {
        headers: Array<string>,
        rows: Array<any>
    },
    FEDataType: 'string' | 'number'
}
export type TollDashboardBadgeValue = {
    data: string | number,
    secondaryData: string | number,
    hideSecondaryData: boolean
}
export type TollDashboardChartValue = {
    labels: Array<string>,
    datasets: Array<{ label: string, data: Array<number>, backgroundColor: string, borderColor: string, pointBackgroundColor: string }>
}
export type TollDashboardTableValue = Array<Array<{ type: 'string' | 'number' | 'numberSeparator' | 'currency' | 'date' | 'dateTime', value: any, key: string }>>;
export const tollDefaultDashboardSkeletonConfig: TollDashboardConfigCache = {
    groups: [
        {
            controls: null,
            id: null,
            customerId: null,
            endpoint: null,
            lastUpdateDate: null,
            type: null,
            FEHidden: false,
            FELoading: false,
            FEWidth: 250,
            FEHeight: 150,
            FEIndex: 0,
            FEChartType: null
        },
        {
            controls: null,
            id: null,
            customerId: null,
            endpoint: null,
            lastUpdateDate: null,
            type: null,
            FEHidden: false,
            FELoading: false,
            FEWidth: 250,
            FEHeight: 150,
            FEIndex: 1,
            FEChartType: null
        },
        {
            controls: null,
            id: null,
            customerId: null,
            endpoint: null,
            lastUpdateDate: null,
            type: null,
            FEHidden: false,
            FELoading: false,
            FEWidth: 250,
            FEHeight: 150,
            FEIndex: 2,
            FEChartType: null
        },
        {
            controls: null,
            id: null,
            customerId: null,
            endpoint: null,
            lastUpdateDate: null,
            type: null,
            FEHidden: false,
            FELoading: false,
            FEWidth: 250,
            FEHeight: 150,
            FEIndex: 3,
            FEChartType: null
        }
    ], layout: 'rows'
}


//BILLINGS
export interface TollBillingsRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        idCliente: number,
        dataCompetenzaDa: string,
        dataCompetenzaA: string,
        dataPagamentoDa: string,
        dataPagamentoA: string,
        totaleDa: number,
        totaleA: number,
        fatturaNumero: string
    }
}
export interface TollBillingsResponse {
    pagination: {
        totalResults: number
    },
    result: {
        id: number,
        fatturaNumero: number,
        dataCompetenza: string,
        totale: number,
        dataPagamento: string,
        clienteDenominazione: string
    }[]
    code: number
}
export interface TollBilling {
    id: number,
    fatturaNumero: number,
    dataCompetenzaDa: string,
    dataCompetenzaA: string,
    totale: number,
    dataPagamento: string,
    clienteDenominazione: string,
    idCliente: number,
    dipartimentoDenominazione: string,
    serviziFatturati: Array<{
        idTipoTransazione: number,
        tempoDiFornituraDa: string,
        tempoDiFornituraA: string,
        qta: number,
        prezzoUnitario: number,
        prezzoNetto: number,
        totale: number
    }>
}

//CONTRACTS
export interface TollContract {
    id: number,
    idVeicolo: number,
    targa: string,
    idLocatario: number,
    locatarioCodice: string,
    dataInizio: string,
    dataFine: string,
    numeroContratto: number,
    isContrattoAperto: boolean,
    idTipoStatusContratto: number,
    clienteDenominazione: string,
    idCliente: number,
    locatarioDenominazione: string,
    dipartimentoDenominazione: string
}
export interface TollContractSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        id: number,
        idCliente: number,
        idVeicolo: number,
        idLocatario: number,
        numeroContratto: string,
        idTipoStatusContratto: number,
        isContrattoAperto: boolean,
        dataInizioDa: string,
        dataInizioA: string,
        dataFineDa: string,
        dataFineA: string,
        idDipartimento: number
    }
}
export interface TollContractSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollContract[]
    code: number
}
export interface TollContractCheckResponse {
    result: boolean,
    message: string,
    totaleTransazioni: number,
    inserimentoLocatario: {
        totaleTransazioniEsportate: number,
        totaleTransazioniNonEsportate: number
    },
    modificaLocatario: {
        totaleTransazioniEsportate: number,
        totaleTransazioniNonEsportate: number
    },
    cancellazioneLocatario: {
        totaleTransazioniEsportate: number,
        totaleTransazioniNonEsportate: number
    }
}

//LESSEES
export interface TollLessee {
    id: number,
    idLocatarioPadre: number,
    codiceLocatarioPadre: string,
    idTipoStatusLocatario: number,
    idCliente: number,
    clienteDenominazione: string,
    codice: string,
    denominazione: string,
    idStato: number,
    statoDenominazione: string,
    indirizzo: number,
    cap: string,
    localita: string,
    email: number,
    telefono: string,
    dataInserimento: string,
    referente: string
}
export interface TollLesseeSearchRequest {
    pagination: {
        startPosition: number,
        maxResult: number,
        orderField: string,
        orderType: string
    },
    query: {
        idCliente: number,
        codice: string,
        denominazione: string,
        idTipoStatusLocatario: number
    }
}
export interface TollLesseeSearchResponse {
    pagination: {
        totalResults: number
    },
    result: TollLessee[]
    code: number
}

//GENERAL
export interface TollSearchObject {
    filters?: object,
    executeSearch: boolean,
    page: 'customers-search' | 'users-search' | 'departments-search' | 'vehicles-search' | 'drivers-search' | 'orders-search' | 'plates-subs-search' |
    'devices-search' | 'transactions-search',
}
export interface TollInputPromptObject {
    inputs: Array<TollInputPromptInput>,
    callback: any,
    title: string
}
export interface TollInputPromptInput {
    type: 'text' | 'select' | 'selectable' | 'toggle' | 'date' | 'dateTime' | 'currency' | 'message', //add types when needed
    required: boolean,
    id: string,
    options?: Array<any>,
    optionsTransalatePath?: string,
    optionsValueProperty?: string,
    optionsTextProperty?: string,
    optionsEndpoint?: string,
    optionsStaticList?: Array<any>,
    multiple?: boolean,
    value?: any,
    disabledEval?: string
}

export const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const vinNumberValidation = /^.{17}$/;
export const yearValidation = /^\d{4}$/;